import Logo from "../../assets/icons/logo.svg?react";

export const FullContainerSpinner = () => {
  return (
    <div className="full-container-spinner relative flex-grow">
      <div className="flex items-center justify-center absolute top-0 left-0 right-0 bottom-0 text-accent1-100 bg-light-100 rounded-12 z-10 min-h-100">
        <Logo className="w-64 h-64 animate-bounce" />
      </div>
    </div>
  );
};
