import {
  forwardRef,
  InputHTMLAttributes,
  MutableRefObject,
  useEffect,
  useRef,
  FC,
} from "react";

import "../../styles/components/customInput.css";

export interface ICustomInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  wrapperClassname?: string;
  autoWidth?: boolean;
  hint?: string;
  leftIcon?: FC<{ className?: string }>;
}

export const CustomInput = forwardRef<HTMLInputElement, ICustomInput>(
  (
    {
      id,
      label,
      type,
      className,
      required,
      wrapperClassname,
      autoWidth,
      leftIcon: LeftIcon,
      hint,
      ...rest
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const finalRef: any = ref || innerRef;

    const autowidth = (ref: MutableRefObject<HTMLInputElement>) => {
      ref.current.style.width = rest.value.toString().length + "ch";
    };

    useEffect(() => {
      if (autoWidth && finalRef.current) {
        autowidth(finalRef);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rest.value]);

    return (
      <div
        data-left-icon={!!LeftIcon}
        data-input-disabled={rest.disabled}
        aria-disabled={rest.disabled}
        className={`custom-input-wrapper ${wrapperClassname || ""}`}
      >
        {label && (
          <label htmlFor={id} className="custom-input-label">
            {label} {required && <span className="text-red-600">*</span>}
          </label>
        )}
        <div className="custom-input-inner-wrapper">
          {LeftIcon && <LeftIcon className="custom-input-left-icon" />}
          <input
            id={id}
            type={type}
            className={`custom-input custom-input-type__${type} ${className || ""}`}
            required={required}
            ref={finalRef}
            {...rest}
          />
        </div>
        {hint && <div className="custom-input-hint">{hint}</div>}
      </div>
    );
  },
);

CustomInput.displayName = "CustomInput";
