export const SearchNotFound = () => {
  return (
    <div className="search-not-found">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="64"
        height="64"
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="m7 17.7l1.4 1.425q.15.15.35.15t.35-.15t.15-.363t-.15-.362L7.7 17l1.425-1.425q.15-.15.15-.35t-.15-.35t-.35-.15t-.35.15L7 16.3l-1.425-1.425q-.15-.15-.35-.15t-.35.15t-.15.35t.15.35L6.3 17l-1.425 1.425q-.15.15-.15.35t.15.35t.35.15t.35-.15zM7 22q-2.075 0-3.537-1.463T2 17t1.463-3.537T7 12t3.538 1.463T12 17t-1.463 3.538T7 22m7.2-7.4q-.3-.325-.638-.663T12.9 13.3q.95-.6 1.525-1.6T15 9.5q0-1.875-1.312-3.187T10.5 5T7.313 6.313T6 9.5q0 .15.013.288t.037.287q-.45.05-.987.2t-.963.35q-.05-.275-.075-.55T4 9.5q0-2.725 1.888-4.612T10.5 3t4.613 1.888T17 9.5q0 1.075-.337 2.038t-.938 1.762l5.575 5.6q.275.275.288.688t-.288.712q-.275.275-.7.275t-.7-.275z"
        />
      </svg>
      По вашему запросу ничего не найдено
    </div>
  );
};
