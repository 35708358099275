import { FC } from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItemsProps,
  MenuItems,
} from "@headlessui/react";

import ArrowDown from "../../assets/icons/arrowDown.svg?react";
import "../../styles/components/menuBtns.css";

export const MenuBtns: FC<{
  active?: {
    name: string;
    value: string | number;
  };
  elements: {
    name: string;
    value: string | number;
    icon?: FC<{ className?: string }>;
  }[];
  handleChange: (opts: { name: string; value: string | number }) => void;
  icon?: FC<{ className?: string }>;
  placeholder?: string;
  menuItemsProps?: MenuItemsProps;
}> = ({
  active,
  handleChange,
  elements,
  placeholder,
  menuItemsProps,
  icon: Icon,
}) => {
  return (
    <Menu>
      <MenuButton className="menu-btn-selected">
        {active?.name || placeholder} {Icon ? <Icon /> : <ArrowDown />}
      </MenuButton>
      <MenuItems
        transition={true}
        anchor="bottom start"
        className="origin-top transition duration-200 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 menu-btns-items"
        {...menuItemsProps}
      >
        {elements.map((i) => {
          const Icon = i?.icon;
          return (
            <MenuItem key={i.name + i.value}>
              <button
                onClick={() => handleChange(i)}
                className={`menu-btns-item menu-btns-item-active__${i.value === active?.value}`}
              >
                {Icon && <Icon className="menu-btns-item-icon" />}
                {i.name}
              </button>
            </MenuItem>
          );
        })}
      </MenuItems>
    </Menu>
  );
};
