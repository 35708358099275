import { FC } from "react";

import "../../styles/components/buttonSwitcher.css";

export const ButtonSwitcher: FC<{
  value: string;
  items: { name: string; value: string }[];
  onChange: (item: { name: string; value: string }) => void;
}> = ({ items, value, onChange }) => {
  return (
    <div className="custom-button-switcher">
      {items.map((i) => (
        <button
          data-switcher-active={i.value === value}
          className="custom-switcher-btn"
          onClick={() => onChange(i)}
          key={i.value}
        >
          {i.name}
        </button>
      ))}
    </div>
  );
};
