import DatePickerKEK, {
  CalendarProps,
  DatePickerProps,
} from "react-multi-date-picker";
import { FC } from "react";

import "../../styles/components/calendar.css";

const weekDays = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const RangeCalendar: FC<
  DatePickerProps<false, true> & CalendarProps<false, true>
> = (props) => {
  return (
    <DatePickerKEK
      weekDays={weekDays}
      months={months}
      range
      placeholder="Выберите дату"
      format="DD.MM.YYYY"
      dateSeparator=" – "
      {...props}
    />
  );
};
