import { useThumbOverlap, Range, getTrackBackground } from "react-range";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";

import "../../styles/components/rangePicker.css";
import { CustomInput } from "./customInput";
import { restrictNumbers } from "../../helpers/common";

const COLORS = [
  "rgba(var(--accent1-70))",
  "rgba(var(--accent1-70))",
  "rgba(var(--accent1-70))",
];

const ThumbLabel = ({
  values,
  index,
}: {
  rangeRef: Range | null;
  values: number[];
  index: number;
}) => {
  return (
    <div
      tabIndex={-1}
      data-label={index}
      style={{
        display: "block",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        top: "calc(-100% - 12px)",
        color: "#fff",
        fontWeight: "500",
        fontSize: "12px",
        minWidth: "24px",
        textAlign: "center",
        padding: "4px",
        borderRadius: "4px",
        backgroundColor: "rgb(var(--accent1-100))",
        whiteSpace: "nowrap",
      }}
    >
      {(values[index] + "").replace(".0", "")}
    </div>
  );
};

export const RangePicker: FC<{
  value: number[];
  onChange: (value: number[]) => void;
  min: number;
  max: number;
  step: number;
  label?: string;
}> = ({ value, onChange, step, max, min, label }) => {
  const rangeRef = useRef<Range>();
  const countInputValues = (v: number[]) => [Math.min(...v), Math.max(...v)];
  const [inputValues, setInputValues] = useState(countInputValues(value));

  const handleBlur = () => {
    const values = countInputValues(inputValues);
    if (value[0] === undefined) value[0] = min;
    if (value[1] === undefined) value[1] = max;
    if (values[0] < min) values[0] = min;
    if (values[1] > max) values[1] = max;

    onChange(values);
  };

  useEffect(() => {
    setInputValues(countInputValues(value));
  }, [value]);

  return (
    <div className="range-picker-wrapper" tabIndex={-1}>
      {label && (
        <div className="range-picker-label">
          {label}
          <div className="range-picker-label-inputs">
            <CustomInput
              value={inputValues[0]}
              onChange={(e) => {
                const onlyNumbersValue = restrictNumbers(e.target.value);
                setInputValues((p) => [+onlyNumbersValue, p[1]]);
              }}
              onBlur={handleBlur}
            />
            —
            <CustomInput
              value={inputValues[1]}
              onChange={(e) => {
                const onlyNumbersValue = restrictNumbers(e.target.value);
                setInputValues((p) => [p[0], +onlyNumbersValue]);
              }}
              onBlur={handleBlur}
            />
          </div>
        </div>
      )}
      <Range
        allowOverlap
        values={value}
        ref={rangeRef}
        step={step}
        min={min}
        max={max}
        onChange={onChange}
        renderTrack={({ props, children }) => (
          <div
            tabIndex={-1}
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "24px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              tabIndex={-1}
              ref={props.ref}
              style={{
                height: "5px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: value,
                  colors: COLORS,
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, index, isDragged }) => {
          return (
            <div
              tabIndex={-1}
              {...props}
              key={props.key}
              style={{
                ...props.style,
              }}
              className={`range-picker-thumb range-picker-thumb-is-drag__${isDragged}`}
            >
              <ThumbLabel
                rangeRef={rangeRef.current}
                values={value}
                index={index}
              />
            </div>
          );
        }}
      />
    </div>
  );
};
